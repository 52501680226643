define("@fleetbase/fleetops-data/models/entity", ["exports", "@ember-data/model", "@ember/object", "date-fns"], function (_exports, _model, _object, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EntityModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.belongsTo)('payload'), _dec13 = (0, _model.belongsTo)('customer', {
    polymorphic: true,
    async: false
  }), _dec14 = (0, _model.belongsTo)('vendor'), _dec15 = (0, _model.belongsTo)('driver'), _dec16 = (0, _model.belongsTo)('tracking-number'), _dec17 = (0, _model.belongsTo)('place'), _dec18 = (0, _model.belongsTo)('file'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string', {
    defaultValue: 'https://flb-assets.s3-ap-southeast-1.amazonaws.com/static/parcels/medium.png'
  }), _dec23 = (0, _model.attr)('string', {
    defaultValue: 'USD'
  }), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string', {
    defaultValue: 'g'
  }), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)('string', {
    defaultValue: 'cm'
  }), _dec33 = (0, _model.attr)('string'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('string'), _dec37 = (0, _model.attr)('string'), _dec38 = (0, _model.attr)('raw'), _dec39 = (0, _model.attr)('date'), _dec40 = (0, _model.attr)('date'), _dec41 = (0, _model.attr)('date'), _dec42 = (0, _object.computed)('length', 'width', 'height', 'dimensions_unit'), _dec43 = (0, _object.computed)('length', 'dimensions_unit'), _dec44 = (0, _object.computed)('width', 'dimensions_unit'), _dec45 = (0, _object.computed)('height', 'dimensions_unit'), _dec46 = (0, _object.computed)('weight', 'weight_unit'), _dec47 = (0, _object.computed)('updated_at'), _dec48 = (0, _object.computed)('updated_at'), _dec49 = (0, _object.computed)('updated_at'), _dec50 = (0, _object.computed)('created_at'), _dec51 = (0, _object.computed)('created_at'), _dec52 = (0, _object.computed)('created_at'), _class = class EntityModel extends _model.default {
    constructor(...args) {
      super(...args);
      /** @ids */
      _initializerDefineProperty(this, "public_id", _descriptor, this);
      _initializerDefineProperty(this, "internal_id", _descriptor2, this);
      _initializerDefineProperty(this, "_import_id", _descriptor3, this);
      _initializerDefineProperty(this, "payload_uuid", _descriptor4, this);
      _initializerDefineProperty(this, "company_uuid", _descriptor5, this);
      _initializerDefineProperty(this, "customer_uuid", _descriptor6, this);
      _initializerDefineProperty(this, "supplier_uuid", _descriptor7, this);
      _initializerDefineProperty(this, "driver_assigned_uuid", _descriptor8, this);
      _initializerDefineProperty(this, "tracking_number_uuid", _descriptor9, this);
      _initializerDefineProperty(this, "destination_uuid", _descriptor10, this);
      _initializerDefineProperty(this, "photo_uuid", _descriptor11, this);
      /** @relationships */
      _initializerDefineProperty(this, "payload", _descriptor12, this);
      _initializerDefineProperty(this, "customer", _descriptor13, this);
      _initializerDefineProperty(this, "supplier", _descriptor14, this);
      _initializerDefineProperty(this, "driver", _descriptor15, this);
      _initializerDefineProperty(this, "trackingNumber", _descriptor16, this);
      _initializerDefineProperty(this, "destination", _descriptor17, this);
      _initializerDefineProperty(this, "photo", _descriptor18, this);
      /** @attributes */
      _initializerDefineProperty(this, "name", _descriptor19, this);
      _initializerDefineProperty(this, "type", _descriptor20, this);
      _initializerDefineProperty(this, "description", _descriptor21, this);
      _initializerDefineProperty(this, "photo_url", _descriptor22, this);
      _initializerDefineProperty(this, "currency", _descriptor23, this);
      _initializerDefineProperty(this, "barcode", _descriptor24, this);
      _initializerDefineProperty(this, "tracking", _descriptor25, this);
      _initializerDefineProperty(this, "qr_code", _descriptor26, this);
      _initializerDefineProperty(this, "weight", _descriptor27, this);
      _initializerDefineProperty(this, "weight_unit", _descriptor28, this);
      _initializerDefineProperty(this, "length", _descriptor29, this);
      _initializerDefineProperty(this, "width", _descriptor30, this);
      _initializerDefineProperty(this, "height", _descriptor31, this);
      _initializerDefineProperty(this, "dimensions_unit", _descriptor32, this);
      _initializerDefineProperty(this, "declared_value", _descriptor33, this);
      _initializerDefineProperty(this, "sku", _descriptor34, this);
      _initializerDefineProperty(this, "price", _descriptor35, this);
      _initializerDefineProperty(this, "sale_price", _descriptor36, this);
      _initializerDefineProperty(this, "slug", _descriptor37, this);
      _initializerDefineProperty(this, "meta", _descriptor38, this);
      /** @dates */
      _initializerDefineProperty(this, "deleted_at", _descriptor39, this);
      _initializerDefineProperty(this, "created_at", _descriptor40, this);
      _initializerDefineProperty(this, "updated_at", _descriptor41, this);
    }
    /** @computed */
    get dimensions() {
      return this.length + 'x' + this.width + 'x' + this.height + ' ' + this.dimensions_unit;
    }
    get displayLength() {
      return this.length + this.dimensions_unit;
    }
    get displayWidth() {
      return this.width + this.dimensions_unit;
    }
    get displayHeight() {
      return this.height + this.dimensions_unit;
    }
    get displayWeight() {
      return this.weight + this.weight_unit;
    }
    get updatedAgo() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }
    get updatedAt() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }
    get updatedAtShort() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'dd, MMM');
    }
    get createdAgo() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }
    get createdAt() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }
    get createdAtShort() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'dd, MMM');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "internal_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_import_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "payload_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customer_uuid", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "supplier_uuid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "driver_assigned_uuid", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tracking_number_uuid", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "destination_uuid", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "photo_uuid", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "payload", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "supplier", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "driver", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "trackingNumber", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "destination", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "photo", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "photo_url", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "barcode", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "qr_code", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "weight", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "weight_unit", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "length", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "dimensions_unit", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "declared_value", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "sku", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "price", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "sale_price", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dimensions", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "dimensions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayLength", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "displayLength"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayWidth", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "displayWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayHeight", [_dec45], Object.getOwnPropertyDescriptor(_class.prototype, "displayHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayWeight", [_dec46], Object.getOwnPropertyDescriptor(_class.prototype, "displayWeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec47], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec48], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec49], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec50], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec51], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec52], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype), _class);
});