define("ember-ref-bucket/modifiers/create-ref", ["exports", "ember-modifier", "@ember/application", "@ember/object", "@ember/debug", "ember-ref-bucket/utils/ref", "ember-ref-bucket/utils/prototype-reference", "@ember/destroyable"], function (_exports, _emberModifier, _application, _object, _debug, _ref, _prototypeReference, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let RefModifier = _exports.default = (_class = class RefModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_key", void 0);
      _defineProperty(this, "_ctx", void 0);
      _defineProperty(this, "_element", void 0);
      // to minimise overhead, user should be specific about
      // what they want to observe
      _defineProperty(this, "defaultMutationObserverOptions", {
        attributes: false,
        characterData: false,
        childList: false,
        subtree: false
      });
      (0, _ref.setGlobalRef)((0, _application.getOwner)(this));
      (0, _destroyable.registerDestructor)(this, () => {
        this.cleanMutationObservers();
        this.cleanResizeObservers();
        (0, _ref.getNodeDestructors)(this._element).forEach(cb => cb());
      });
    }
    markDirty() {
      (0, _ref.bucketFor)(this._ctx).dirtyTrackedCell(this._key);
    }
    cleanMutationObservers() {
      if (this._mutationsObserver) {
        this._mutationsObserver.disconnect();
      }
    }
    cleanResizeObservers() {
      if (this._resizeObserver) {
        this._resizeObserver.unobserve(this.element);
      }
    }
    installMutationObservers(named = {}) {
      this._mutationsObserver = new MutationObserver(this.markDirty);
      const opts = this.getObserverOptions(named);
      delete opts.resize;
      if (opts.attributes || opts.characterdata || opts.childlist) {
        // mutations observer throws if observe is attempted
        // with all these options disabled
        this._mutationsObserver.observe(this.element, opts);
      }
    }
    validateTrackedOptions(named = {}) {
      const args = ['subtree', 'attributes', 'children', 'resize', 'character'];
      if (args.some(name => name in named)) {
        (true && !(this.isTracked(named)) && (0, _debug.assert)(`"ember-ref-modifier", looks like you trying to use {{${named.debugName}}} without tracked flag or alias, but, with properties, related to tracked modifier (${args.join(', ')})`, this.isTracked(named)));
      }
    }
    getObserverOptions(named = {}) {
      // to minimise overhead user
      // should be specific about
      // what they want to observe
      let resize = false;
      let subtree = this.defaultMutationObserverOptions.subtree;
      let attributes = this.defaultMutationObserverOptions.attributes;
      let character = this.defaultMutationObserverOptions.characterData;
      let children = this.defaultMutationObserverOptions.childList;
      if ('subtree' in named) {
        subtree = named.subtree;
      }
      if ('attributes' in named) {
        attributes = named.attributes;
      }
      if ('children' in named) {
        children = named.children;
      }
      if ('resize' in named) {
        resize = named.resize;
      }
      if ('character' in named) {
        character = named.character;
      }
      return {
        subtree,
        attributes,
        childList: children,
        resize,
        characterData: character
      };
    }
    installResizeObservers(element) {
      this._resizeObserver = new ResizeObserver(this.markDirty);
      this._resizeObserver.observe(element);
    }
    modify(element, positional, named) {
      const name = this.name(positional);
      const ctx = this.ctx(named, positional);
      this._key = name;
      this._ctx = ctx;
      this._element = element;
      (true && !(typeof name === 'string' && name.length > 0) && (0, _debug.assert)(`You must provide string as first positional argument for {{${named.debugName}}}`, typeof name === 'string' && name.length > 0));
      this.validateTrackedOptions(named);
      this.cleanMutationObservers();
      this.cleanResizeObservers();
      if (name !== this._key || this._ctx !== ctx) {
        (0, _ref.bucketFor)(this._ctx).add(this._key, null);
      }
      (0, _ref.watchFor)(name, ctx, () => {
        const keys = (0, _prototypeReference.getReferencedKeys)(ctx, name);
        keys.forEach(keyName => {
          // consume keys with callbacks
          ctx[keyName];
        });
      });
      (0, _ref.bucketFor)(ctx).add(name, element);
      if (this.isTracked(named)) {
        this.installMutationObservers(named);
        if (this.getObserverOptions(named).resize) {
          this.installResizeObservers(element);
        }
      }
    }
    ctx(named = {}, positional = [undefined]) {
      (true && !(named.bucket !== null) && (0, _debug.assert)(`ember-ref-bucket: You trying to use {{${named.debugName}}} as local reference for template-only component. Replace it to {{global-ref "${positional[0]}"}}`, named.bucket !== null));
      return named.bucket || (0, _application.getOwner)(this);
    }
    isTracked(named = {}) {
      return named.tracked || false;
    }
    name(positional) {
      return positional[0];
    }
  }, _applyDecoratedDescriptor(_class.prototype, "markDirty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markDirty"), _class.prototype), _class);
});